import React, { Component } from "react"
// import Header from "../components/header/header"
import Section1 from "./Section1"
import Section2 from "./Section2"
import "../styles/global.scss"
// import ReactPageScroller from "react-page-scroller"
import ReactPageScroller from "../components/fullpage/index"
import { Pager } from "react-bootstrap"
import LogoSite from "../components/logo/logo"

// var prevScrollPos = 0
// let elements = null
// let currentElement = 0

// window.addEventListener("scroll", () => {
//   console.log(elements)
//   if (window.scrollY > prevScrollPos) {
//     elements[currentElement + 1].scrollIntoView()
//     // console.log("+")
//     // window.scrollTo(0, 1300)
//   } else {
//     elements[currentElement - 1].scrollIntoView()
//     // console.log("-")
//   }
//   prevScrollPos = window.scrollY
// })

class Fullpage extends Component {
  // componentDidMount = () => {
  //   elements = document.getElementById("fullpage").children

  //   for (let [i, v] of Object.entries(elements)) {
  //     document
  //       .getElementById("fullpage")
  //       .children[i].setAttribute("id", `section-${i}`)
  //   }
  // }
  constructor(props) {
    super(props)
    this.state = {
      currentPage: null,
      currentPage2: null,
      midldeLogoPosition: null,
    }
  }

  handlePageChange = number => {
    this.setState({ currentPage: number }) // set currentPage number, to reset it from the previous selected.
  }
  handleDynamicChange = number => {
    if (number === 0) {
      this.setState({ currentPage2: 0 })
    } else {
      this.setState({ currentPage2: 1 })
    }
  }

  getPagesNumbers = () => {
    const pageNumbers = []

    for (let i = 1; i <= 5; i++) {
      pageNumbers.push(
        <Pager.Item key={i} eventKey={i - 1} onSelect={this.handlePageChange}>
          {i}
        </Pager.Item>
      )
    }

    return [...pageNumbers]
  }
  midldeLogoPosition = null
  componentDidMount() {
    this.midldeLogoPosition = window.innerWidth
  }

  render() {
    // const pagesNumbers = this.getPagesNumbers()
    const currentPage = this.state.currentPage
    // const currentPage2 = this.state.currentPage2

    return (
      <>
        <ReactPageScroller
          pageOnChange={this.handlePageChange}
          customPageNumber={this.state.currentPage}
          doWithChange={this.handleDynamicChange}
        >
          <Section1 />
          <Section2 />
        </ReactPageScroller>
        {/* <Pager className="pagination-additional-class" bsSize="large">
          {pagesNumbers}
        </Pager> */}
        <LogoSite currentPage={currentPage} />
      </>
    )
  }
}

const IndexPage = () => (
  <>
    {/* <Header /> */}
    <Fullpage />
  </>
)

export default IndexPage
