import React from "react"
import { motion } from "framer-motion"
import useWindowSize from "../../hook/useWindowSize"
import Logo from "../../images/logo-white.svg"
import "./style.scss"

const LogoSite = props => {
  const { width } = useWindowSize()
  console.log("=========" + width)

  const variantsDesc = {
    logoCenter: {
      top: "40vh",
      left: "50vw",
      marginLeft: "-375px",
      maxWidth: "80%",
      width: "750px",
    },
    logoTop: {
      top: "10px",
      left: "8vw",
      marginLeft: "0px",
      width: "230px",
    },
  }

  const variantsMob = {
    logoCenter: {
      top: "40vh",
      left: "50vw",
      marginLeft: "-160px",
      width: "320px",
    },
    logoTop: {
      top: "10px",
      left: "50vw",
      marginLeft: "-110px",
      width: "220px",
    },
  }

  return (
    <div className="logoSite">
      <motion.img
        id="logo"
        initial="logoCenter"
        animate={props.currentPage > 0 ? "logoTop" : "logoCenter"}
        variants={width < 700 ? variantsMob : variantsDesc}
        transition={{ ease: "easeOut", duration: 1 }}
        className="logo"
        src={Logo}
        alt="logo"
      />
    </div>
  )
}

export default LogoSite
